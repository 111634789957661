import React, { useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import CallIcon from '@mui/icons-material/Call';
import CallEndIcon from '@mui/icons-material/CallEnd';
import MicOffRounded from '@mui/icons-material/MicOffRounded';
import MicRounded from '@mui/icons-material/MicRounded';
import { useTranslation } from 'hooks/useTranslation';
import { useCall } from 'hooks';
import { Device } from 'twilio-client';
import useTeam from 'hooks/useTeam';

let call;

export const CallActions = React.memo(({ device }: { device: Device }) => {
  const { translate } = useTranslation();
  const { hangUpCall, callInfo } = useCall();
  const [callStatus, setCallStatus] = useState('ready');
  const [mute, setMute] = useState<boolean>(false);
  const { team } = useTeam();
  const { twilioRecordings } = team;

  const handleMute = () => {
    if (!device) return;
    if (call && call.isMuted()) call.mute(false);
    else call.mute(true);

    setMute((prev) => !prev);
  };

  const handleEndCall = () => {
    try {
      if (device && device.disconnectAll) device.disconnectAll();
      hangUpCall();
      setCallStatus('ready');
    } catch (error) {
      throw error;
    }
  };

  const handleMakeCall = () => {
    if (callInfo?.phone) {
      call = device?.connect({
        clientNumber: callInfo?.phone,
        callerId: callInfo?.callerId,
        leadId: callInfo?.leadId,
        teamId: callInfo?.teamId,
        userId: callInfo?.userId,
        record: twilioRecordings?.record || 'do-not-record'
      });
      setCallStatus('call-in-progress');
    }
  };

  return (
    <Box display="flex" justifyContent="space-evenly" alignItems="center">
      <IconButton
        sx={(theme) => ({
          color: theme.palette.common.white,
          backgroundColor: theme.palette.success.main,
          '&:hover': {
            backgroundColor: theme.palette.success.dark
          },
          '&:disabled': {
            backgroundColor: theme.palette.divider
          }
        })}
        onClick={handleMakeCall}
        disabled={callStatus !== 'ready'}
      >
        <CallIcon sx={{ fontSize: '1.5rem' }} />
      </IconButton>

      <IconButton
        onClick={handleMute}
        disabled={callStatus !== 'call-in-progress'}
        sx={(theme) => ({
          '&:disabled': {
            backgroundColor: theme.palette.divider
          }
        })}
      >
        <Box width={30} height={30} display="flex" flexDirection="column" alignItems="center">
          {mute ? (
            <MicOffRounded
              sx={{
                fontSize: 20
              }}
            />
          ) : (
            <MicRounded sx={{ fontSize: 20 }} />
          )}
          <Typography variant="caption" sx={{ fontSize: 8 }}>
            {mute ? translate('Unmute') : translate('Mute')}
          </Typography>
        </Box>
      </IconButton>

      <IconButton
        sx={(theme) => ({
          color: theme.palette.common.white,
          backgroundColor: theme.palette.error.main,
          '&:hover': {
            backgroundColor: theme.palette.error.dark
          },
          '&:disabled': {
            backgroundColor: theme.palette.divider
          }
        })}
        onClick={handleEndCall}
      >
        <CallEndIcon sx={{ fontSize: '1.5rem' }} />
      </IconButton>
    </Box>
  );
});
